@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    background: #1A1A1A;
}


.App {
    font-family: Montserrat;
    width: 1072px;
    height: fit-content;
    margin: 0 auto;
    > .header {
        display: grid;
        grid-template-columns: 32px auto;
        grid-template-rows: 32px auto;
        width: fit-content;
        grid-gap: 10px 16px;
        position: relative;
        grid-template-areas:
            "logo label"
            "name name"
        ;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 50px;
        margin-bottom: 50px;
        > .logo {
            grid-area: logo;
            width: 32px;
        }
        > .label {
            grid-area: label;
            font-size: 1.5rem;
            font-weight: 800;
            background: linear-gradient(180deg, #BB19FD 0%, #6711E5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            display: flex;
            align-items: center;
            line-height: 32px;
            > .white {
                color: #FFFFFF;
            }
        }
        > .name {
            grid-area: name;
            font-size: 1.5rem;
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
        }
    }
    > .category {
        margin-top: 20px;
        > .label {
            font-size: 2.5rem;
            font-weight: 600;
            text-align: center;
            background: linear-gradient(180deg, #BB19FD 0%, #6711E5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
        > .cars {
            grid-template-columns: repeat(4, 256px);
            grid-auto-rows: 256px;
            display: grid;
            grid-gap: 16px;
            margin-top: 30px;
            > .car {
                width: 100%;
                height: 100%;
                background-size: 100% 100%;
                border-radius: 10px;
                position: relative;
                overflow: hidden;
                > .overlay {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 48px;
                    background: #00000088;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    font-size: 1.25rem;
                }
            }
        }
    }
    > .footer {
        margin-top: 50px;
        margin-bottom: 30px;
        text-align: center;
        color: white;
        font-weight: 600;
        > a {
            color: white;
            text-decoration: none;
            background: linear-gradient(180deg, #BB19FD 0%, #6711E5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
}